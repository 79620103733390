import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components/macro'
import { BrowserRouter as Router } from 'react-router-dom'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'
import * as Sentry from '@sentry/react'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'
import { GlobalStyle } from './components/GlobalStyle'
import { GrowthBookProviderWrapper } from './components/GrowthBookProviderWrapper/GrowthBookProviderWrapper'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f93a9ffcb14ae03aa7a44bc1b26d45be@o4508121156222976.ingest.de.sentry.io/4508772396499024',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

SuperTokens.init({
  appInfo: {
    appName: 'Patient Onboarding',
    apiDomain: `${window.location.protocol}//${window.location.host}`,
    websiteDomain: `${window.location.protocol}//${window.location.host}`,
    apiBasePath: '/auth',
    websiteBasePath: '/api/onboarding',
  },
  recipeList: [Session.init()],
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle grayBody />
    <SuperTokensWrapper>
      <GrowthBookProviderWrapper>
        <Router>
          <App />
        </Router>
      </GrowthBookProviderWrapper>
    </SuperTokensWrapper>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
