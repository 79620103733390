// Exceptions and errors declared as enum
export const EXCEPTIONS = {
  LostConnection: 'LOST_INTERNET_CONNECTION',
  NoSuchBooking: 'NO_SUCH_BOOKING',
  AlreadyCompleted: 'ALREADY_COMPLETED',
  SubmissionFailed: 'SUBMISSION_FAILED',
  PaymentFailed: 'PAYMENT_FAILED',
  FailedAuthentication: 'AUTHENTICATION_FAILED',
}

export const DECLARATION_STATUS = {
  CANCELLED: 'Cancelled',
  INCOMPLETE: 'Incomplete',
  INACTIVE: 'Inactive',
  PATIENT_ARRIVED_TO_CLINIC: 'PatientArrivedToClinic',
}

export const ERROR_STATUS = Object.values(DECLARATION_STATUS)
