import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { ErrorScreen } from './ErrorScreen'
import { ConfigContext } from '../config'
import { ScreenHeader } from './ScreenHeader'
import { Button } from './Button'
import { Form } from './Form'
import { Screen } from './Screen'
import { getDateLocale } from '../utils/locales'

export function ConfirmationScreen({
  title,
  formDescription,
  noProgress,
  progress,
  currentScreen,
  screenIndex,
  screensLength,
  btnConfig,
}) {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { errorObject, healthDeclarationData } = useContext(ConfigContext)
  const locale = i18n.language.split('-')[0]
  const navigateToPreviousPage = () => history.goBack()

  const handlerBackSite = () => {
    const { hostname } = window.location
    const hostnameSplit = hostname.split('.')
    if (hostnameSplit[1] && hostnameSplit[2]) {
      const url = `https://${hostnameSplit[1]}.${hostnameSplit[2]}`
      window.location.replace(url)
    }
  }

  const confirmationData = useMemo(() => {
    if (healthDeclarationData.length !== 0) {
      const data = [
        {
          label: t('confirmationLabels.0'),
          value: `${healthDeclarationData?.caregiver?.firstname} ${healthDeclarationData?.caregiver?.lastname}`,
        },
        {
          label: t('confirmationLabels.1'),
          value: `${healthDeclarationData?.clinic_name}: ${healthDeclarationData?.clinic_address}`,
        },
        {
          label: t('confirmationLabels.2'),
          value: `${
            healthDeclarationData?.start
              ? format(new Date(healthDeclarationData?.start), 'EEEE d. MMM', {
                  locale: getDateLocale(locale),
                })
              : ''
          }`,
        },
        {
          label: t('confirmationLabels.3'),
          value: `${
            healthDeclarationData?.start
              ? format(new Date(healthDeclarationData?.start), 'HH:mm')
              : ''
          }`,
        },
        {
          label: t('confirmationLabels.4'),
          value: t('confirmationLabels.5'),
        },
      ]

      return data
    }
    return []
  }, [healthDeclarationData])

  return errorObject ? (
    <ErrorScreen onClick={navigateToPreviousPage} errorObject={errorObject} />
  ) : (
    <StyledConfirmationScreen>
      {!noProgress && (
        <ScreenHeader
          title={title}
          progress={progress}
          currentScreen={currentScreen}
          screenIndex={screenIndex}
          screensLength={screensLength}
        />
      )}
      <ContentContainer>
        <ScrollContainer id="scroll-container">
          <StyledForm>
            <Title>{title}</Title>
            {formDescription && typeof formDescription === 'object' ? (
              formDescription.map((item, index) => <Description key={index}>{item}</Description>)
            ) : (
              <Description>{formDescription}</Description>
            )}
            {confirmationData &&
              confirmationData.map((item, index) => (
                <TableItem key={index}>
                  <TableRow style={{ fontWeight: 'bold' }}>{item.label}</TableRow>
                  <TableRow>{item.value}</TableRow>
                </TableItem>
              ))}
            {btnConfig && <Button onClick={handlerBackSite}>{btnConfig.children}</Button>}
          </StyledForm>
        </ScrollContainer>
      </ContentContainer>
    </StyledConfirmationScreen>
  )
}

ConfirmationScreen.propTypes = {
  title: PropTypes.string,
  formDescription: Form.propTypes.description,
  noProgress: PropTypes.bool,
  progress: Screen.propTypes.progress,
  currentScreen: PropTypes.number,
  screenIndex: PropTypes.number.isRequired,
  screensLength: PropTypes.number.isRequired,
  btnConfig: PropTypes.shape(Button.propTypes),
}

ConfirmationScreen.defaultProps = {
  title: '',
  noProgress: Screen.defaultProps.noProgress,
  progress: Screen.defaultProps.progress,
  formDescription: Form.defaultProps.description,
  currentScreen: 0,
  btnConfig: Button.defaultProps,
}

const ScrollContainer = styled.div`
  max-height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    width: 7px;
    background: ${props => props.theme.color.white};
    border-radius: 25px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.darkGray};
    border-radius: 25px;
  }
`

const ContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow-y: hidden;
  background: ${props => {
    return props.theme.color.white
  }};
`

const Description = styled.p`
  font-size: ${props => props.theme.typography.size.breadM};
  font-weight: ${props => props.theme.typography.weight.regular};
  line-height: ${props => props.theme.typography.height.normal};
  line-height: 24px;
  color: ${props => props.theme.color.darkBlue};
  margin: 0 0 24px;
`

const StyledForm = styled.div`
  border-radius: 10px;
  background: ${props => {
    if (props.type === 'authorization') {
      return 'none'
    }
    return `#fff`
  }};
  padding: 10px 24px;
  box-sizing: border-box;
  box-shadow: none;
`

const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const TableRow = styled.span`
  line-height: 1.8;
`

const Title = styled.h1`
  font-size: ${props => props.theme.typography.size.heading1};
  font-weight: ${props => props.theme.typography.weight.bold};
  color: ${props => props.theme.color.darkBlue};
  line-height: 30px;
  position: relative;
  padding: 0 0 10px;
  margin: ${props => {
    if (props.type === 'authorization') {
      return '0 0 8px'
    }
    return '0 0 8px'
  }};

  &::after {
    content: '';
    width: 32px;
    height: 2px;
    background: ${props => props.theme.color.orange};
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 4px;
  }
`

const StyledConfirmationScreen = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &.fade-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  &.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  &.fade-exit {
    opacity: 1;
    transform: scale(1);
  }

  &.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`
